import { useCallback } from "react";

const LandingPageVersion1 = () => {
  const onFrameLinkClick = useCallback(() => {
    window.open(
      "https://tank9.gotrackier.com/click?campaign_id=19&pub_id=25&source=Lp1"
    );
  }, []);

  const onFrameContainer3Click = useCallback(() => {
    window.open("https://tank9.gotrackier.com/click?campaign_id=19&pub_id=25&source=Lp2");
  }, []);

  const onFrameContainer8Click = useCallback(() => {
    window.open(
      "https://tank9.gotrackier.com/click?campaign_id=19&pub_id=25&source=Lp2"
    );
  }, []);

  const onFrameLink1Click = useCallback(() => {
    window.open("https://funexch.app/");
  }, []);

  const onFrameLink2Click = useCallback(() => {
    window.open("http://wa.link/funexch ");
  }, []);

  return (
    <div className="relative bg-gray-100 w-full h-[860px] flex flex-col items-start justify-start pt-0 px-4 pb-8 box-border gap-[20px] text-center text-base text-white font-poppins">
      <div className="self-stretch flex flex-col items-start justify-start gap-[20px] z-[0] mt-8">
       
        <div className="self-stretch flex flex-row items-start justify-end gap-[66px]">
          <img
            className="relative w-[121.21px] h-8 object-cover"
            alt=""
            src="/image-50@2x.png"
          />
          <div className="self-stretch rounded-md bg-gray-200 w-[34px] flex flex-row items-center justify-center py-[7px] px-2 box-border">
            <img
              className="relative w-4 h-4 overflow-hidden shrink-0"
              alt=""
              src="/fi-512142.svg"
            />
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-center gap-[24px] text-xs font-roboto-semibold-16">
          <img
            className="relative rounded-lg w-[343px] h-[190px] object-cover"
            alt=""
            src="/image-52@2x.png"
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[12px] text-left">
            <a
              className="[text-decoration:none] self-stretch rounded-lg [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-3.5 cursor-pointer text-[inherit]"
              onClick={onFrameLinkClick}
            >
              <div className="flex-1 flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[12px]">
                  <img
                    className="relative w-8 h-8 object-cover"
                    alt=""
                    src="/freestarbadge39662583286975-1@2x.png"
                  />
                  <div className="flex-1 relative tracking-[0.3px] leading-[150%] font-medium">
                    Get your free ID with 6% bonus on every deposit
                  </div>
                </div>
                <div
                  className="rounded-81xl [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-2 cursor-pointer"
                  onClick={onFrameContainer3Click}
                >
                  <img
                    className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                    alt=""
                    src="/leftarrow-1.svg"
                  />
                </div>
              </div>
            </a>
            <div
              className="self-stretch rounded-lg [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-3.5 cursor-pointer"
              onClick={onFrameContainer8Click}
            >
              <div className="flex-1 flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[12px]">
                  <img
                    className="relative w-8 h-8 object-cover"
                    alt=""
                    src="/pngtree3dregisternowbuttonvectorpngimage-7113995-1@2x.png"
                  />
                  <div className="flex-1 relative tracking-[0.3px] leading-[150%] font-medium">
                    Register now
                  </div>
                </div>
                <div className="rounded-81xl [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-2">
                  <img
                    className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                    alt=""
                    src="/leftarrow-1.svg"
                  />
                </div>
              </div>
            </div>
            <a
              className="[text-decoration:none] self-stretch rounded-lg [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-3.5 cursor-pointer text-[inherit]"
              href="https://funexch.app/"
              onClick={onFrameLink1Click}
            >
              <div className="flex-1 flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[12px]">
                  <img
                    className="relative w-8 h-8 object-cover"
                    alt=""
                    src="/downloadcloud-1@2x.png"
                  />
                  <div className="flex-1 relative tracking-[0.3px] leading-[150%] font-medium">
                    Download App
                  </div>
                </div>
                <div className="rounded-81xl [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-2">
                  <img
                    className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                    alt=""
                    src="/leftarrow-1.svg"
                  />
                </div>
              </div>
            </a>
            <a
              className="[text-decoration:none] self-stretch rounded-lg [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-3.5 cursor-pointer text-[inherit]"
              href="http://wa.link/funexch"
              onClick={onFrameLink2Click}
            >
              <div className="flex-1 flex flex-row items-center justify-between">
                <div className="flex-1 flex flex-row items-center justify-start gap-[12px]">
                  <img
                    className="relative w-8 h-8 object-cover"
                    alt=""
                    src="/3915171-1@2x.png"
                  />
                  <div className="flex-1 relative tracking-[0.3px] leading-[150%] font-medium">
                    Get ID on WhatsApp
                  </div>
                </div>
                <div className="rounded-81xl [background:linear-gradient(92.42deg,_rgba(72,_205,_131,_0.1),_rgba(10,_116,_58,_0.1))] flex flex-row items-center justify-center p-2">
                  <img
                    className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                    alt=""
                    src="/leftarrow-1.svg"
                  />
                </div>
              </div>
            </a>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px] text-base">
            <div className="self-stretch relative tracking-[0.3px] leading-[150%] font-semibold">
              18+ Users Promotes only
            </div>
            <div className="self-stretch rounded-lg [background:linear-gradient(97.73deg,_rgba(54,_226,_250,_0.1),_rgba(235,_255,_191,_0.1))] overflow-hidden flex flex-row items-center justify-start p-4 relative gap-[20px] text-left text-[14px]">
              <div className="absolute my-0 mx-[!important] right-[0px] bottom-[166px] [background:linear-gradient(97.73deg,_#36e2fa,_#ebffbf)] [filter:blur(314px)] w-[151px] h-24 z-[0]" />
              <div className="flex-1 flex flex-row items-center justify-start z-[1]">
                <b className="flex-1 relative tracking-[0.3px] leading-[150%] uppercase">
                  <span   class="font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-white to-[#48cd83]  " >
                    World cup
                  </span>
                  <span>{` dekhne ka Fun,Aur paise kamane ka fun, Ab ek hi `}</span>
                  <span className="font-extrabold text-transparent text-8xl bg-clip-text bg-gradient-to-r from-white to-[#48cd83]  ">
                    platform pe.
                  </span>
                </b>
              </div>
              <div className="rounded-81xl [background:linear-gradient(97.73deg,_rgba(54,_226,_250,_0.1),_rgba(235,_255,_191,_0.1))] flex flex-row items-center justify-center p-2 z-[2]">
                <img
                  className="relative w-3.5 h-3.5 overflow-hidden shrink-0"
                  alt=""
                  src="/leftarrow-11.svg"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start text-[9px] text-gray-300">
            <div className="self-stretch flex flex-col items-center justify-start pt-0 px-0 pb-2 relative">
              <img
                className="relative w-[121.21px] h-8 object-cover z-[0]"
                alt=""
                src="/image-501@2x.png"
              />
              <div className="my-0 mx-[!important] absolute w-[109.32%] right-[-4.66%] bottom-[-16px] left-[-4.66%] flex flex-row items-start justify-start py-0 pr-[270.46002197265625px] pl-[19.740001678466797px] box-border z-[1]">
                <div className="self-stretch flex flex-col items-center justify-start">
                  <div className="self-stretch relative leading-[13.5px] font-medium inline-block max-h-[13.5px]">
                    Cookie Preferences
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-0 mx-[!important] absolute bottom-[0px] left-[calc(50%_-_187.5px)] w-[375px] flex flex-col items-center justify-center p-2.5 box-border z-[1]">
        <div className="relative box-border w-[91px] h-[3px] border-t-[3px] border-solid border-white" />
      </div>
    </div>
  );
};

export default LandingPageVersion1;
